import { ERROR_CODES } from '../constants';
import { useUserStore } from '../store/userStore';

import gatewayApi from './gatewayApi';
import iamApi from './iamApi';
import registryApi from './registryApi';

function applyInterceptors(instances) {
  for (const instance of instances) {
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (
          error.response?.data?.type === 'authorization-required' ||
          error.response?.data?.type === 'invalid-authorization'
        ) {
          const refreshed = await useUserStore.getState().keycloak.updateToken();
          if (refreshed) {
            const token = `Bearer ${useUserStore.getState().keycloak.token}`;
            gatewayApi.defaults.headers.Authorization = token;
            iamApi.defaults.headers.Authorization = token;
            registryApi.defaults.headers.Authorization = token;
            // Retry request
            return instance({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: token,
              },
            });
          } else {
            return useUserStore.getState().handleLogout();
          }
        }
        if (error.response?.data?.type === 'insufficient-permissions') {
          throw ERROR_CODES.INSUFFICIENT_PERMISSION;
        }

        return Promise.reject(error);
      },
    );
  }
}

applyInterceptors([gatewayApi, iamApi, registryApi]);
