import { useQuery } from '@tanstack/react-query';

import Spinner from '../../../../../../components/Spinner';
import Stack from '../../../../../../components/Stack';
import gatewayApi from '../../../../../../services/gatewayApi';
import { useDataUnitStore } from '../../../../../../store/dataUnitStore';
import Card2 from '../../../../../../components/Card2';
import DataSchemaTable from '../../../../../../components/DataSchemaTable/DataSchemaTable';

const DataUnitSchemaTab = () => {
  const { dataUnit, setDataUnitData } = useDataUnitStore();
  const { isLoading: isDataUnitSchemaLoading } = useQuery(
    ['dataUnitSchema', dataUnit?.entity?.identifier],
    async () => {
      const res = await gatewayApi.get(`/data_unit/${dataUnit?.entity?.identifier}/schema`);
      return res.data;
    },
    {
      onSuccess: ({ fields }) => {
        setDataUnitData({ dataUnit: { ...dataUnit, fields } });
      },
    },
  );

  return (
    <Stack gap={2} style={{ width: '100%' }}>
      <Card2
        title="Descriptions, PII and classification"
        bodyStyle={{
          padding: 0,
        }}
      >
        {isDataUnitSchemaLoading ? (
          <Spinner />
        ) : (
          <DataSchemaTable data={dataUnit?.fields} maxFullHeight type="data_unit" />
        )}
      </Card2>
    </Stack>
  );
};

export default DataUnitSchemaTab;
