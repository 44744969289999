import config from './config.json';

export const FEATURE_FLAG = import.meta.env.VITE_FEATURE_FLAG;

export const IAM_API_BASE_URL = config.NC_IAM_API;

export const REGISTRY_BASE_URL = config.NC_REGISTRY_API;

export const GENERAL_ERR_MSG = 'Oops. Something went wrong, please try again.';

export const spacingUnit = 8;

export const TOPBAR_HEIGHT = 70;

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'DD MMM YYYY hh:mm A';
export const DATE_TIME_FORMAT_2 = 'MMMM DD, YYYY hh:mm A';

export const CAST_TYPE_TO_PSQL_TYPE_MAP = {
  string: 'VARCHAR',
  integer: 'INTEGER',
  long: 'BIGINT',
  float: 'REAL',
  double: 'DOUBLE',
  timestamp: 'TIMESTAMP',
  date: 'DATE',
  boolean: 'BOOLEAN',
  decimal: 'DECIMAL',
};

export const ERROR_CODES = {
  INSUFFICIENT_PERMISSION: 'insufficient-permissions',
};

export const API_ERROR_TYPE_TO_MESSAGE_MAP = {
  // Gateway
  'k8s-application-already-exists': 'Application already exists.',
  'k8s-application-invalid-state': 'Application in invalid state.',
  'k8s-application-not-found': 'Application not found.',
  'k8s-application-not-ready': 'Application not ready.',
  'k8s-application-run-not-found': 'Application run not found.',
  'k8s-already-exists': 'Kuberneters object already exists.',
  'k8s-communication-error': 'Kubernetes communication error.',
  'k8s-not-found': 'Kubernetes object not found.',
  'k8s-root-application-not-found': 'Root application not found.',
  'k8s-run-creation-error': 'Application run creation failed.',
  'k8s-secret-already-exists': 'Secret already exists.',
  'k8s-secret-not-found': 'Secret not found.',
  'k8s-unexpected-postgres-error': 'Unexpected postgres error.',
  'k8s-unhandled-error': 'Unhandled k8s-manager error.',
  'k8s-unsupported-request-type': 'Unsupported request type.',
  'k8s-validation-failed': 'Validation error.',
  'kafka-client-error': 'Kafka client error.',
  'kafka-resource-not-found': 'Kafka resource not found.',
  'kafka-topic-exists': 'Topic already exists.',
  'kafka-validation-error': 'Invalid input.',
  'postgres-connection-error': 'Postgres connection failure.',
  'row-exists': 'Row already exists.',
  'row-not-found': 'Row not found.',
  'trino-schema-unsupported': 'Unsupported schema action.',
  'trino-statement-syntax-error': 'Statement syntax error.',
  'trino-table-already-exists': 'Table already exists.',
  'trino-unsupported-type': 'Unsupported type.',
  'unexpected-postgres-error': 'Unhandled postgres error occurred.',
  'unhandled-kafka-error': 'Unhandled Kafka error.',
  'unhandled-trino-error': 'Unhandled Trino error.',
  'data-source-connection-secret-exists': 'DataSource connection secret already exists.',
  'data-product-exists': 'Data Product already exists.',
  'data-porduct-invalid-builder': 'Data product builder invalid.',
  'data-product-missing-builder': 'Data product missing builder.',
  'data-product-missing-info': 'Data product entity_info missing.',
  'data-product-missing-schema': 'Data product missing schema.',
  'data-product-not-found': 'Data Product not found.',
  'data-product-published': 'Data product has been published.',
  'data-product-schema-defined': 'The name cannot be changed because the Data product table is already defined.',
  'data-product-spark-job-assigned': 'Spark job already assigned.',
  'data-product-spark-job-not-assigned': 'Spark job not assigned.',
  'data-product-spark-job-not-scheduled': 'Spark job not scheduled.',
  'data-product-spark-job-scheduled': 'Spark job already scheduled.',
  'data-product-streaming-action-error': 'Invalid streaming product action.',
  'data-product-using-builder': 'Data product using builder.',
  'data-source-connector-connection-required': 'DataSource connection required.',
  'data-source-exists': 'Data Source already exists.',
  'data-source-missing-info': 'DataSource entity_info missing.',
  'data-source-not-found': 'Data Source not found.',
  'data-system-exists': 'Data System already exists.',
  'data-system-missing-info': 'DataSystem entity_info missing.',
  'data-system-not-found': 'Data System not found.',
  'data-unit-exists': 'Data Unit already exists.',
  'data-unit-missing-configuration': 'DataUnit missing configuration.',
  'data-unit-missing-info': 'DataUnit entity_info missing.',
  'data-unit-missing-schema': 'DataUnit missing schema.',
  'data-unit-not-found': 'Data Unit not found.',
  'data-unit-update-schema-error': 'DataUnit schema update error.',
  'element-already-exists': 'Element already exists.',
  'element-not-found': 'Element not found.',
  'empty-secret': 'Empty secret not allowed.',
  'entity-already-has-parent': 'Entity already linked to a parent.',
  'entity-has-children': 'Entity can not be deleted with linked children.',
  'entity-not-found': 'Entity not found.',
  'field-not-found': 'Field not found.',
  'invalid-quality-parameters': 'Invalid data quality expectation settings.',
  'invalid-spark-config': 'Invalid spark configuration.',
  'invalid-spark-file': 'Invalid spark file.',
  'journal-note-exists': 'Journal note already exists.',
  'data-source-missing-connection': 'DataSource connection required to add secret.',
  'output-exists': 'Output already exists.',
  'output-missing-info': 'Output entity_info missing.',
  'output-not-found': 'Output not found.',
  'restricted-link-action': 'Entity can not be linked to itself.',
  'restricted-link-removal-action': 'System Entity can not be removed from its parent.',
  'secret-connected': 'Secret connected.',
  'secret-exists': 'Secret already exists.',
  'spark-exists': 'Spark already exists.',
  'system-element-immutable': 'System element is immutable.',
  'system-tag-immutable': 'System tag is immutable.',
  'tag-in-use': 'Tag in use.',
  'webhook-error': 'Webhook error.',
  'webhook-wrong-data-format': 'Webhook wrong data format.',
  // HUB
  'authorization-required': 'Authorization token required.',
  'identity-access-manager-error': 'Problem with Identity Access Manager.',
  'insufficient-permissions': 'Insufficient permissions.',
  'invalid-authorization': 'Authorization token invalid.',
  'invalid-resource-format': 'Resource has invalid format.',
  'service-connection-error': 'Encountered errors while attempting to connect to or process requests for the service.',
  'signature-error': 'Signature invalid.',
  'access-key-id-exists': 'Access key id already exists.',
  'access-key-id-not-found': 'Access key id not found.',
  'registry-core-already-in-status': 'Subscription to data product is already in given status.',
  'registry-core-already-registered': 'Core already registered.',
  'registry-core-already-subscribed': 'Core already subscribed to a data product.',
  'registry-core-has-data-products': 'Core still has data product(s).',
  'registry-core-host-not-defined': 'Core host is not defined.',
  'registry-code-not-registered': 'Core not registered.',
  'registry-core-not-subscribed': 'Core not subscribed to a data product.',
  'registry-data-product-already-registered': 'Data Product already registered.',
  'registry-data-product-has-active-subscriptions': 'Data product has active subscriptions.',
  'registry-data-product-not-registered': 'Data Product not registered.',
  'duplicate-entry': 'Duplicate entry',
  'failed-authorization': 'Authorization failed.',
  'gateway-callback-error': 'Could not call gateway callback.',
  'group-not-found': 'Group not found.',
  'missing-header': 'Missing header.',
  'not-allowed': 'Not enough permissions.',
  'policy-not-found': 'Policy not found.',
  'principal-not-found': 'Principal not found.',
  'secret-keys-maximum-count': 'Maximum number of secret keys reached.',
  'system-entry': 'System entry',
  'registry-unexpected-postgres-error': 'Unhandled registry postgres error occurred.',
  'user-manager-service-error': 'Problem with User Manager Service.',
  'user-manager-service-get-users-error': 'Could not get users from User Manager Service.',
  'user-manager-service-user-field-error': 'Missed fields in the user description.',
  'user-not-found': 'User not found.',
  'validation-error': 'Validation error',
};
