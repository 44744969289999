import dayjs from 'dayjs';

import Icon from '../components/Icon';
import Text from '../components/Text';
import { DATE_TIME_FORMAT_2, API_ERROR_TYPE_TO_MESSAGE_MAP, GENERAL_ERR_MSG } from '../constants';

/**
 *
 * @param {String} str Some string.
 * @returns Capitalized string.
 */
export const capitalizeFirstLetter = (str) => (str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '');

/**
 * Returns a value that is nested in an object by dot notation string path.
 * @param {String} path Path to property value in dot notation.
 * @param {Object} object Object to read value from.
 * @returns Value of property.
 */
export const getNestedPropertyValue = (path, object) =>
  path && object ? path.split('.').reduce((o, prop) => o?.[prop], object) : null;

/**
 * General NEOS name sanitizer
 * @param {string} str
 * @returns Sanitized string
 */
export const sanitizeString = (str) => str.toLowerCase().replace(/-|\s/g, '_');

/**
 * Formats a snake_case string to words.
 * @param {string} string
 * @returns {string}
 * @example
 * formatSnakeCaseToWords('some_snake_case_string') // 'Some snake case string'
 */
export const formatSnakeCaseToWords = (string) => {
  return capitalizeFirstLetter(string).split('_').join(' ');
};

/**
 * Formats system created entity description
 * @param {object} entityData
 * @returns {React.JSX} fromated description
 */
export const getSystemCreatedEntityDescription = (entityData) => {
  return (
    <Text tagName="span" disableGutter>
      {entityData?.description || ''} <br />
      <Text tagName="span" disableGutter style={{ fontSize: '10px' }}>
        <Icon name="cog" color="#D8DDE3" secondaryColor="#FFFFFF" /> Auto-generated
      </Text>
    </Text>
  );
};

/**
 * Based on the provided contact identifiers and users list, returns a string of usernames separated by comma.
 * @param {array} contactIds list of entity contact identifiers
 * @param {array} users list of users
 * @returns {string} formated list of usernames
 */
export const getContactsData = (contactIds = [], users = []) => {
  const usernameList = [];

  contactIds.forEach((id) => {
    const user = users.find((u) => u.identifier === id);

    if (user) {
      usernameList.push(user.username);
    }
  });

  return usernameList.join(', ');
};

/**
 * Based on the provided timestamp, returns a of more human readable time format using dayjs calendar plugin.
 * @param {string} timestamp valid timestamp from BE
 * @returns {{
 *  sameDay: '[Today,] hh:mm A'|'[seconds] [seconds ago]'|'[minutes] [minutes ago]'|'[hours] [hours ago]',
 *  lastDay: '[Yesterday,] hh:mm A',
 *  lastWeek: DATE_TIME_FORMAT_2,
 *  sameElse: DATE_TIME_FORMAT_2
 * }}
 */
export const getTimeCalendarFormat = (timestamp) => {
  const now = dayjs();
  let sameDayFormat = '[Today,] hh:mm A';
  const minutesDiff = now.diff(dayjs(timestamp), 'minutes');
  if (minutesDiff <= 5 * 60) {
    if (minutesDiff < 1) {
      const secondsDiff = now.diff(dayjs(timestamp), 'seconds');
      const seconds = secondsDiff < 2 ? 'second' : 'seconds';
      sameDayFormat = `[${secondsDiff}] [${seconds} ago]`;
    } else if (minutesDiff < 60) {
      const minutes = minutesDiff < 2 ? 'minute' : 'minutes';
      sameDayFormat = `[${minutesDiff}] [${minutes} ago]`;
    } else {
      const hoursDiff = now.diff(dayjs(timestamp), 'hours');
      const hours = hoursDiff < 2 ? 'hour' : 'hours';
      sameDayFormat = `[${hoursDiff}] [${hours} ago]`;
    }
  }

  return {
    sameDay: sameDayFormat,
    lastDay: '[Yesterday,] hh:mm A',
    lastWeek: DATE_TIME_FORMAT_2,
    sameElse: DATE_TIME_FORMAT_2,
  };
};

/**
 * @param {String} input Input from the transformation.
 * @returns {String} Input that can be read by the transformation components.
 */
export const sanitizeTransformationInput = (input) => {
  if (!input) return '';

  if (/^(input_|\d+)/.test(input)) {
    return input.split('input_').pop().toLowerCase().replace(/_|\s/g, '-');
  }
  return input;
};

export const getApiErrorMessage = (apiErrorResponse) => {
  return API_ERROR_TYPE_TO_MESSAGE_MAP[apiErrorResponse?.type] || apiErrorResponse?.title || GENERAL_ERR_MSG;
};
